import axios from 'axios'
import router from '../router/index'
import { Message, MessageBox } from 'element-ui'
import { Spin } from 'view-design';
//* 储存token 
function setToken(token) {
    return localStorage.setItem("token", token)
}

//* 清除token
function removeToken() {
    return localStorage.removeItem("token");
}

//* 清除userinfo
function removeUserinfo() {
    return localStorage.removeItem("userinfo");
}


//* 清除项目管理的滚动缓存
function removescrollTop() {
    return localStorage.removeItem("scrollTop");
}


const devBaseUrl = '/apis/api/'; //开发环境
// const devBaseUrl = 'http://medical.com/api/'; //开发环境
// const proBaseUrl = 'http://medical.gongshicloud.com/api/'; //生产环境
// const proBaseUrl = 'http://medical.netloop.cn/api/'; //生产环境    
//旧的 http://api.grifols.netloop.cn 
const proBaseUrl = 'https://api.allist.medcase.com.cn/api/'; //生产环境      
let url; 
process.env.NODE_ENV === 'development' ? url = devBaseUrl : url = proBaseUrl;
// 创建axios实例 
const service = axios.create({
    baseURL: url,
    timeout: 10000
})

service.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    return config
})

//刷新tokne接口
function getTokenApi() {
    return new Promise((resolve, reject) => {
        service.post('auth/refresh').then(res => {
            if (res.data.code == 200) {
                resolve(res.data.data)
            } else {
                reject(res.data)
            }
        })
    })
}


// 是否正在刷新的标记
let isRefreshing = false
    //重试队列
let requests = []
service.interceptors.response.use(
    response => {
        if (response.data.code === 200) {
            return Promise.resolve(response)
                //约定code 401 token 过期
        } else if (response.data.code === 401) {
            if (!isRefreshing) {
                isRefreshing = true
                return getTokenApi().then(res => {
                    const token = res.access_token
                    setToken(token)
                    response.config.headers.Authorization = `Bearer ${token}`
                        //Message.success('更新了token');
                    response.config.headers.withCredentials = true;
                    requests.length > 0 && requests.map((cb) => {
                        cb();
                    });
                    requests = []; //注意要清空
                    return service(response.config)

                }).catch(err => {
                    window.alert('登录过期，请重新登录')
                        //removeCode() 
                    window.location.reload() //! 新加的 还没有测试
                    removeToken()
                    removeUserinfo()
                    removescrollTop()
                    window.location.reload()
                    router.push('/homeTopic')
                    return Promise.reject(err)
                }).finally(() => {
                    Spin.hide();
                    isRefreshing = false
                })
            } else {
                // 返回未执行 resolve 的 Promise
                return new Promise(resolve => {
                    // 用函数形式将 resolve 存入，等待刷新后再执行
                    requests.push(token => {
                        response.headers.Authorization = `Bearer ${token}`
                        resolve(service(response.config))
                    })
                })
            }
        } else if (response.data.code === 407) {
            //tokne407 用户不存在 请求冲登录
            Message.error(response.data.message)
            removeToken()
            removeUserinfo()
            removescrollTop()
                //removeCode()
            window.location.reload()
                // router.push('/home') 
            return Promise.reject(response.data)
        } else if (response.data.code === 405) {
            // token405 彻底过期不能在刷新 
            removeToken()
            removeUserinfo()
            removescrollTop()
                //removeCode()
            router.push('/homeTopic')
            Message.error(response.data.message)
            return Promise.reject(response.data)
        } else {
            Message.error(response.data.message)
            return Promise.reject(response.data)
        }
    },
    (error) => {
        Spin.hide();
        //Message.error(error.response.data.msg)
        return Promise.reject(error)
    }
)

export default service;